<template >
  <div id="blog">
    <div id="headerHome" class="col-sm-12 col-md-12 color-bg-1">
      <HeaderHome :name="user.fullname" />
    </div>
    
    <form @submit.prevent="
                enviarEmail(
                    nombre, a_materno, a_paterno, fecha_n, email, tel, telof, nombre_m, tipo_m, ubi_dr_partido, funcion_desp, dirc_m, razon_social_m, emailc
                )" >
        <div class="prensa-cont">
        <h3 class="sub-t-prensa">REGISTRO DE DATOS <span>PARA ACREDITACIÓN</span></h3>
        <div class="col-prensa">
            <div class="input-contact">
                <h6>NOMBRE</h6>
                <input type="text" 
                name="nombre" 
                id="text-field-contact"
                placeholder="Nombre" required v-model="nombre">
            </div>
            <div class="input-contact">
                <h6>APELLIDO PATERNO</h6>
                <input type="text" 
                name="apellido-paterno" 
                id="text-field-contact"
                placeholder="Apellido paterno" required v-model="a_paterno">
            </div>
            <div class="input-contact">
                <h6>APELLIDO MATERNO</h6>
                <input type="text" 
                name="apellido-materno" 
                id="text-field-contact"
                placeholder="Apellido materno" required v-model="a_materno">
            </div>
            <div class="input-contact">
                <h6>FECHA DE NACIMIENTO</h6>
                <input type="date" 
                name="fecha" 
                id="text-field-contact"
                placeholder="Ej. 07/OCTUBRE/200" required v-model="fecha_n">
            </div>
        </div>
        <div class="col-prensa">
            <div class="input-contact">
                <h6>EMAIL</h6>
                <input type="email" 
                name="email" 
                id="text-field-contact"
                placeholder="E-Mail" required v-model="email">
            </div>
            <div class="input-contact">
                <h6>CONFIRMAR EMAIL</h6>
                <input type="email" 
                name="confirmar-email" 
                id="text-field-contact"
                placeholder="Confirmar E-Mail" required v-model="emailc">
            </div>
        </div>
        <div class="col-prensa">
            <div class="input-contact">
                <h6>TELÉFONO CELULAR</h6>
                <input type="text" 
                name="telefono" 
                id="text-field-contact"
                placeholder="Teléfono celular" required v-model="tel">
            </div>
            <div class="input-contact">
                <h6>TELÉFONO OFICINA</h6>
                <input type="text" 
                name="telefono-oficina" 
                id="text-field-contact"
                placeholder="Teléfono oficina" required v-model="telof">
            </div>
        </div>


        <h3 class="sub-t-prensa">INFORMACIÓN <span>DEL MEDIO</span></h3>
        <div class="col-prensa">
            <div class="input-contact">
                <h6>NOMBRE DEL MEDIO</h6>
                <input type="text" 
                name="nombre-medio" 
                id="text-field-contact"
                placeholder="Nombre del medio" required v-model="nombre_m">
            </div>
            <div class="input-contact">
                <h6>TIPO DE MEDIO</h6>
                <!--<input type="text" 
                name="tipo-medio" 
                id="text-field-contact"
                placeholder="Tipo de medio" >-->
                <select name="tipo-medio" 
                id="text-field-contact" required v-model="tipo_m">
                    <option disabled>SELECCIONE</option>
                    <option >TV</option>
                    <option >Radio</option>
                    <option >Medio Digital</option>
                    <option >Agencia</option>
                    <option >Impreso</option>
                    <option >Otro</option>
                </select>
            </div>
            <div class="input-contact">
                <h6>UBICACIÓN DURANTE LOS PARTIDOS</h6>
                <select
                name="ubicacion-partidos" 
                id="text-field-contact" required v-model="ubi_dr_partido">
                    <option disabled>SELECCIONE</option>
                    <option >Palco de Medios</option>
                    <option >Cancha</option>
                    <option >Cabina de Transmisión</option>
                    <option >Posición de cámaras</option>
                    <option >Gradas</option>
                </select>
            </div>
        </div>
        <div class="col-prensa">
            <div class="input-contact">
                <h6>FUNCIÓN A DESEMPEÑAR</h6>
                <select
                name="funcion" 
                id="text-field-contact" required v-model="funcion_desp">
                    <option disabled>SELECCIONE</option>
                    <option >Reportero</option>
                    <option >Fotógrafo</option>
                    <option >Camarógrafo</option>
                    <option >Narrador</option>
                    <option >Otro</option>
                </select>
            </div>
            <div class="input-contact" >
                <h6>DIRECCIÓN DEL MEDIO</h6>
                <input type="text" 
                name="direccion-medio" 
                id="text-field-contact"
                placeholder="Dirección del medio" required v-model="dirc_m" >
            </div>
            <div class="input-contact" required>
                <h6>RAZÓN SOCIAL DEL MEDIO</h6>
                <input type="text" 
                name="razon-social" 
                id="text-field-contact"
                placeholder="Razón social del medio" required  v-model="razon_social_m">
            </div>
        </div>

        
        <!--<h3 class="sub-t-prensa">DATOS <span>DEL MEDIO</span></h3>
        <div class="col-prensa">
            <div class="input-contact">
                <h6>NOMBRE COMPLETO DEL MEDIO</h6>
                <input type="text" 
                name="nombre-c-medio" 
                id="text-field-contact"
                placeholder="Nombre completo del medio" >
            </div>
            <div class="input-contact">
                <h6>CORREO ELECTRÓNICO DEL EDITOR</h6>
                <input type="text" 
                name="correo-editor" 
                id="text-field-contact"
                placeholder="Correo electrónico del editor" >
            </div>
            <div class="input-contact">
                <h6>TELÉFONO DEL EDITOR</h6>
                <input type="text" 
                name="telefono-editor" 
                id="text-field-contact"
                placeholder="Teléfono del editor" >
            </div>
        </div>
        <div class="col-prensa">
            <div class="input-contact">
                <h6>CONTRASEÑA</h6>
                <input type="text" 
                name="contrasenia" 
                id="text-field-contact"
                placeholder="Contraseña" >
            </div>
            <div class="input-contact">
                <h6>REPETIR CONTRASEÑA</h6>
                <input type="text" 
                name="rep-contrasenia" 
                id="text-field-contact"
                placeholder="Repetir contraseña" >
            </div>
            <div class="input-contact">
                <h6>FOTOGRAFÍA</h6>
                <input type="button" 
                name="fotografia" 
                id="text-field-contact" 
                class="sub-foto"
                value="Subir Imagen">
            </div>
        </div>-->
        <input type="submit" 
        name="crear-cuenta" 
        id="text-field-contact" 
        class="submit-crear"
        value="ENVIAR">
        <div v-if="this.status =='success'">
                <p class="cart_msg cart_success">{{this.msg}}</p>
             </div>
              <div v-if="this.status =='error'">
                <p class="cart_msg cart_error">{{this.msg}}</p>
             </div>
    </div>
    </form>
    <div id="footerSection">
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../components/footer";
import HeaderHome from "../components/HeaderHome";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {
        nombre:"", 
        a_materno:"", 
        a_paterno:"", 
        fecha_n:"", 
        email:"", 
        emailc:"",
        tel:"", 
        telof:"",
        nombre_m:"", 
        tipo_m:"SELECCIONE", 
        ubi_dr_partido:"SELECCIONE", 
        funcion_desp:"SELECCIONE", 
        dirc_m:"", 
        razon_social_m:"",
        status:"",
        msg:""
    };
  },
  components: {
    HeaderHome,
    Footer,
  },
  async beforeMount() {
    let token = await this.$store.dispatch("admin/getToken");
    this.$store.dispatch("admin/decode", token);
  },
  computed: {
    user() {
      return this.$store.getters["admin/getIdentity"];
    },
  },
  methods:{
      delStatus: function () {
        setTimeout(() => this.delMsgs()
        ,5000);
        setTimeout(() => this.close()
        ,3000);
      },
      delMsgs: function(){
        this.status=""
        this.msg=""
      },
      enviarEmail: async function(nombre, a_materno, a_paterno, fecha_n, email, tel, telof, nombre_m, tipo_m, ubi_dr_partido, funcion_desp, dirc_m, razon_social_m, emailc){
         this.msg = ''
         this.status =''

         let cvf = fecha_n.split('-')
         fecha_n = cvf[1]+'/'+cvf[2]+'/'+cvf[0]

         if(email != emailc){
             this.status='error'
              this.msg='Los emails no son iguales.'
              this.delStatus()
         }else if(tipo_m == 'SELECCIONE' || ubi_dr_partido =='SELECCIONE' || funcion_desp =='SELECCIONE'){
             if(tipo_m =='SELECCIONE'){
                 this.status='error'
                this.msg='Seleccione un tipo de medio.'
                this.delStatus()
             }else if(ubi_dr_partido =='SELECCIONE'){
                 this.status='error'
                this.msg='Seleccione una ubicación durante los partidos valida.'
                this.delStatus()
             }else if(funcion_desp =='SELECCIONE'){
                 this.status='error'
                this.msg='Seleccione una función a desempeñar valida.'
                this.delStatus()
             }
         }else{
             let data = {
                "nombre":nombre, 
                "a_materno":a_materno,
                "a_paterno":a_paterno, 
                "fecha_n":fecha_n, 
                "email":email,
                "tel":tel, 
                "telof":telof, 
                "nombre_m":nombre_m, 
                "tipo_m":tipo_m, 
                "ubi_dr_partido":ubi_dr_partido, 
                "funcion_desp":funcion_desp, 
                "dirc_m":dirc_m, 
                "razon_social_m":razon_social_m
                }
                
             let response = await this.$store.dispatch("admin/enviarMailPrensa", data);
             if(response.status =='success'){
               this.status='success'
               this.msg='Mensaje enviado con exito.'


               this.delStatus()
             }else{
                this.status='error'
               // this.msg='Lo sentimos ha ocurrido un error al intentar entregar tu mensaje.'
               this.msg = response.message
                this.delStatus()
             } 
         }

           
      }
  }
};
</script>

<style scoped>
.prensa-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 7.917vw 0;
}.col-prensa {
    display: flex;
    padding-bottom: 4.167vw;
    margin-bottom: 4.167vw;
    border-bottom: 1px solid gray;
    width: 65.156vw;
    justify-content: center;
}.input-contact {
    margin: 0 0.573vw;
}h3.sub-t-prensa {
    font-size: 2.344vw;
    line-height: 2.708vw;
    color: #B70D1D;
    font-family: 'Rajdhani';
    margin-bottom: 4.167vw;
}h3.sub-t-prensa span {
    color: #01203a;
    font-family: 'Rajdhani';
}.input-contact h6 {
    font-size: 0.781vw;
    line-height: 1.458vw;
    text-transform: uppercase;
    color: #B70D1D;
    font-family: 'Rajdhani';
    margin-bottom: 0.781vw;
}#text-field-contact {
    width: 17.083vw;
    height: 3.646vw;
    background-color: transparent;
    border: 1px solid #032A4B;
    font-size: 0.781vw;
    line-height: 2.685vw;
    color: #8293A2;
    padding: 0 2vw;
}.sub-foto {
    background: #002340!important;
    color: white!important;
    text-transform: capitalize!important;
    background-image: url('../assets/img-hover.png')!important;
    background-size: 1.2vw!important;
    background-repeat: no-repeat!important;
    background-position-x: 4.5vw!important;
    background-position-y: center!important;
}input#text-field-contact::placeholder{
    font-size: 0.781vw;
    line-height: 0.885vw;
}.submit-crear {
    background: var(--color-2)!important;
    border: none!important;
    color: white!important;
    background-image: url('../assets/Arrow_6.png')!important;
    background-repeat: no-repeat!important;
    background-position-y: 1.7vw!important;
    background-position-x: 0!important;
    background-size: 0!important;
    padding: 0 7.2vw!important;
    display: flex!important;
}.submit-crear:hover{
    background-repeat: no-repeat!important;
    background-position-y: 1.7vw!important;
    background-position-x: 12.74vw!important;
    background-size: 3.073vw!important;
    padding: 0 2vw!important;
}select#text-field-contact {
  -webkit-appearance: none;
   -moz-appearance: none;
   appearance: none;
   background-image: url('../assets/flecha-blue.png');  
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 14.5vw;
    background-size: 0.6vw;
}

@media (max-width: 768px) {
.prensa-cont {
    padding: 35.917vw 14.976vw 7.917vw;
    align-items: flex-start;
}h3.sub-t-prensa {
    font-size: 6.039vw;
    line-height: 6.039vw;
    margin: 10.039vw 0;
    text-align: left;
}.col-prensa {
    display: flex;
    padding-bottom: 3.167vw;
    margin-bottom: 0;
    border-bottom: 0;
    width: 100%;
    justify-content: center;
    flex-direction: column;
}#text-field-contact {
    width: 70.29vw;
    height: 21.739vw;
    font-size: 3.623vw;
    line-height: 3.623vw;
    padding: 0 4vw;
}input#text-field-contact::placeholder{
    font-size: 3.623vw;
    line-height: 3.623vw;
}.input-contact h6 {
    font-size: 3.623vw;
    line-height: 3.623vw;
    text-transform: uppercase;
    color: #B70D1D;
    font-family: 'Rajdhani';
    margin-bottom: 3.623vw;
    margin-top: 6.623vw;
}.sub-foto {
    background: #002340!important;
    color: white!important;
    text-transform: capitalize!important;
    background-image: url('../assets/img-hover.png')!important;
    background-size: 5.2vw!important;
    background-repeat: no-repeat!important;
    background-position-x: 17.5vw!important;
    background-position-y: center!important;
}.submit-crear {
    background: var(--color-2)!important;
    border: none!important;
    color: white!important;
    background-image: url('../assets/Arrow_6.png')!important;
    background-repeat: no-repeat!important;
    background-position-y: center!important;
    background-position-x: 0!important;
    background-size: 0!important;
    padding: 0 29vw!important;
    display: flex!important;
}.submit-crear:hover{
    background-repeat: no-repeat!important;
    background-position-y: center!important;
    background-position-x:52.74vw!important;
    background-size: 13.073vw!important;
    padding: 0 6vw!important;
}select#text-field-contact {
    background-position-x: 60.5vw;
    background-size: 3.1vw;
}
}
</style>